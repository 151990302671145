import { InfoCircleOutlined } from "@ant-design/icons";
import { GetProps, Tooltip } from "antd";
import { Text } from "./Typography";
interface TooltipIconProps extends Omit<GetProps<typeof Tooltip>, "children"> {
  icon?: React.ReactNode;
  iconClassName?: string;
}
function TooltipIcon({
  icon,
  iconClassName,
  title,
  ...toolTipProps
}: TooltipIconProps) {
  if (title === undefined) {
    return null;
  }
  return <Tooltip title={title} {...toolTipProps} data-sentry-element="Tooltip" data-sentry-component="TooltipIcon" data-sentry-source-file="Tooltip.tsx">
      <div className={iconClassName}>
        <Text type="secondary" data-sentry-element="Text" data-sentry-source-file="Tooltip.tsx">{icon ?? <InfoCircleOutlined />}</Text>
      </div>
    </Tooltip>;
}
export { Tooltip, TooltipIcon };