"use client";

import { Input, InputNumber } from "antd";
import type { InputProps, InputRef } from "antd";
import type { SearchProps } from "antd/es/input/Search";
import { useEffect, useState } from "react";
import { isValidPrecision } from "../utils/math";
type CustomNumberInputProps = Omit<InputProps, "onChange" | "min" | "max" | "value"> & {
  precision?: number;
  value?: number | null;
  onChange?: (value?: number | null) => void;
};
function CustomNumberInput({
  value,
  onChange,
  precision,
  allowClear = true,
  onBlur,
  ...rest
}: CustomNumberInputProps) {
  const [displayValue, setDisplayValue] = useState<string | undefined>();
  useEffect(() => {
    setDisplayValue(typeof value === "number" ? value.toString() : undefined);
  }, [value]);
  return <Input {...rest} allowClear={allowClear} value={displayValue} onChange={e => {
    const inputVal = e.target.value;
    const inputNumber = Number(inputVal);
    if (!inputVal) {
      // Empty string, set data value to Null.
      onChange?.(null);
    } else if (!Number.isNaN(inputNumber)) {
      // Valid number, set data value to valid number
      onChange?.(Number(inputVal));
    }
    // Skip setting data value if invalid number.
    setDisplayValue(inputVal);
  }} onBlur={e => {
    const inputVal = e.target.value;
    const inputNumber = Number(inputVal);
    if (!inputVal) {
      // Empty string / undefined
      setDisplayValue(undefined);
    } else if (!Number.isNaN(inputNumber)) {
      // Valid number, set to correct precision.
      const newValue = isValidPrecision(precision) ? Number(inputVal).toFixed(precision) : inputVal;
      onChange?.(Number(newValue));
      setDisplayValue(newValue);
    } else {
      // invalid number, reset to previous value.
      setDisplayValue(value?.toString());
    }
    onBlur?.(e);
  }} data-sentry-element="Input" data-sentry-component="CustomNumberInput" data-sentry-source-file="Input.tsx" />;
}
export { Input, InputNumber, CustomNumberInput, SearchProps, InputRef };