"use client";

import clsx from "clsx";
import { CSSProperties, useEffect, useState } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import { Paragraph } from "../Typography";
import { EditorMenuBar } from "./EditorMenuBar";
import "./style.css";
interface RichTextEditorProps {
  value?: string;
  style?: CSSProperties;
  maxLength?: number;
  onChange?: (v: string | undefined) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  autoFocus?: boolean;
}
export function RichTextEditor({
  value = "",
  style,
  maxLength,
  onChange,
  onFocus,
  onBlur,
  autoFocus
}: RichTextEditorProps) {
  const [isFocused, setIsFocused] = useState(false);
  const lengthCounter = value?.length || 0;
  const editor = useEditor({
    extensions: [StarterKit, Table.configure({
      resizable: false
    }), TableRow, TableHeader, TableCell, Link.configure({
      openOnClick: true,
      autolink: true,
      linkOnPaste: true
    })],
    // There's a known bug where the autofocus prop does not work,
    // need to unset it for false to work.
    ...(autoFocus ? {
      autofocus: true
    } : {}),
    editable: true,
    onFocus: () => {
      onFocus?.();
      setIsFocused(true);
    },
    onBlur: () => {
      onBlur?.();
      setIsFocused(false);
    },
    onUpdate: async ({
      editor
    }) => {
      const htmlValue = editor?.getHTML();
      onChange?.(editor.isEmpty ? undefined : htmlValue);
    }
  });
  useEffect(() => {
    if (editor) {
      const commandChain = editor.chain().setContent(value);
      if (autoFocus) {
        commandChain.focus().run();
      } else {
        commandChain.run();
      }
    }
  }, [editor]);
  return <div data-sentry-component="RichTextEditor" data-sentry-source-file="index.tsx">
      <div style={style} className={clsx("rich-text-editor", {
      focused: isFocused
    })}>
        <EditorMenuBar editor={editor} data-sentry-element="EditorMenuBar" data-sentry-source-file="index.tsx" />
        <Paragraph style={{
        margin: 0
      }} data-sentry-element="Paragraph" data-sentry-source-file="index.tsx">
          <EditorContent className="rich-text-editor-content" editor={editor} data-sentry-element="EditorContent" data-sentry-source-file="index.tsx" />
        </Paragraph>
      </div>
      {maxLength && maxLength > 0 && <div style={style} className={clsx("rich-text-editor-counter", {
      error: lengthCounter > maxLength
    })}>
          {lengthCounter} / {maxLength}
        </div>}
    </div>;
}