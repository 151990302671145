export enum Namespace {
  GLOBAL = "global",
  COMMON = "common",
  AI_LOCALIZATION = "localization",
  TASK_LOG = "taskLog",
  PRODUCT = "product",
  PRODUCT_DETAILS = "productDetails",
  CATALOG = "catalog",
  CATALOG_EDIT = "catalogEdit",
  COMPANY_PROFILE_EDIT = "companyProfileEdit",
  COMPANY_PROFILE_VIEW = "companyProfileView",
  DASHBOARD = "dashboard",
  ACCOUNT_MANAGEMENT = "accountManagement",
  QUOTATION_REQUESTS_VIEW = "quotationRequestsView",
  QUOTATION_REQUEST_DETAIL = "quotationRequestsDetail",
  SALE_OPPORTUNITY = "saleOpportunity",
  ATTRIBUTE_CONFIGURATION = "attributeConfiguration",
  ADD_ATTRIBUTE = "addAttribute",
  GETTING_STARTED = "gettingStarted",
  AUTH = "auth",
  SMART_UPLOAD = "smartUpload",
  ENRICHMENT = "enrichment",
  ENRICHMENT_IMAGE_REVIEW = "enrichmentImageReview",
  EXPORT_SETTINGS = "exportSettings",
  API_KEY = "apiKey",
  VARIANT_EDIT = "variantEdit",
  TEMPLATE_DOWNLOAD = "templateDownload",
  CHANNEL_CONFIGURATION = "channelConfiguration",
  DISTRIBUTION = "distribution",
  DAM = "dam",
}
