"use client";

import { Namespace } from "@/src/i18n/namespace";
import { useNotificationContext } from "@/src/pattern-library";
import { useTranslations } from "next-intl";

export function useCopy() {
  const { notification } = useNotificationContext();
  const tCommon = useTranslations(Namespace.COMMON);

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
    notification.success({ message: tCommon("shared.copied") });
  };

  return {
    copyText,
  };
}
