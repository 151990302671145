"use client";

import React, { createContext, useContext, useMemo } from "react";
import type { NotificationInstance } from "antd/es/notification/interface";
import { notification } from "antd";
interface NotificationContextType {
  notification: NotificationInstance;
}
interface NotificationProviderProps {
  children: React.ReactNode;
}
export const NotificationContext = createContext<NotificationContextType | undefined>(undefined);
export const NotificationProvider = ({
  children
}: NotificationProviderProps) => {
  const [message, messageContext] = notification.useNotification();
  const contextValue: NotificationContextType = useMemo(() => ({
    notification: message
  }), []);
  return <NotificationContext.Provider value={contextValue} data-sentry-element="unknown" data-sentry-component="NotificationProvider" data-sentry-source-file="NotificationProvider.tsx">
      {messageContext}
      {children}
    </NotificationContext.Provider>;
};
export function useNotificationContext() {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error("useNotificationContext must be used within NotificationProvider");
  }
  return context;
}