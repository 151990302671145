import React from "react";
import { Text } from "./Typography";
import { Flex } from "./Flex";
import { theme } from "antd";
export { Form } from "antd";
export type { FormProps, FormRule, FormItemProps, FormListOperation, FormListFieldData, FormInstance } from "antd";
export type { Rule, FormListProps } from "antd/es/form";
interface FormLabelProps {
  id: string;
  required?: boolean;
  children: React.ReactNode;
}
export function FormLabel({
  id,
  required,
  children
}: FormLabelProps) {
  const {
    token: {
      marginXXS,
      paddingXS
    }
  } = theme.useToken();
  return <label className="inline-flex" htmlFor={id} style={{
    paddingBottom: paddingXS
  }} data-sentry-component="FormLabel" data-sentry-source-file="Form.tsx">
      {required ? <Text style={{
      marginRight: marginXXS
    }} type="danger">
          *
        </Text> : null}

      {children}
    </label>;
}
interface FormHelperTextProps {
  errorMessage?: string;
  help?: React.ReactNode;
  className?: string;
}
export function FormHelperText({
  errorMessage,
  help,
  className
}: FormHelperTextProps) {
  return <Flex gap="large" justify="space-between" className={className} data-sentry-element="Flex" data-sentry-component="FormHelperText" data-sentry-source-file="Form.tsx">
      <Text size="SM" type="danger" data-sentry-element="Text" data-sentry-source-file="Form.tsx">
        {errorMessage}
      </Text>
      {help}
    </Flex>;
}