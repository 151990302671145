"use client";

import { Editor } from "@tiptap/react";
import { Modal } from "../Modal";
import { Form } from "../Form";
import { Input } from "../Input";
import { useTranslations } from "next-intl";
import { Namespace } from "@/src/i18n/namespace";
import { useState } from "react";
interface FieldType {
  url?: string;
}
interface AddLinkModalProps {
  editor?: Editor | null;
  open: boolean;
  setOpen: (value: boolean) => void;
}
const isValidUrl = (href: string) => /^https?:\/\//.test(href);
export const SetLinkModal = ({
  editor,
  open,
  setOpen
}: AddLinkModalProps) => {
  const tCommon = useTranslations(Namespace.COMMON);
  const [url, setUrl] = useState("");
  const canSubmit = isValidUrl(url);
  const onSubmit = async () => {
    if (!editor) {
      return;
    }
    setOpen(false);
    editor.chain().focus().setLink({
      href: url,
      target: "_blank"
    }).run();
  };
  return <Modal title={tCommon("textEditor.setLink")} open={open} okText={tCommon("textEditor.confirm")} okButtonProps={{
    disabled: !canSubmit
  }} onOk={onSubmit} onCancel={() => setOpen(false)} data-sentry-element="Modal" data-sentry-component="SetLinkModal" data-sentry-source-file="SetLinkModal.tsx">
      <Form name="addLink" layout="vertical" autoComplete="off" data-sentry-element="Form" data-sentry-source-file="SetLinkModal.tsx">
        <Form.Item<FieldType> label={tCommon("textEditor.linkUrl")} name="url" rules={[{
        required: true
      }, {
        validator: (_, value: string) => {
          if (!value.length) {
            return Promise.resolve();
          }
          return isValidUrl(value) ? Promise.resolve() : Promise.reject(new Error(tCommon("textEditor.enterValidUrl")));
        }
      }]} data-sentry-element="unknown" data-sentry-source-file="SetLinkModal.tsx">
          <Input placeholder="https://www.website.com" onChange={e => setUrl(e.target.value)} data-sentry-element="Input" data-sentry-source-file="SetLinkModal.tsx" />
        </Form.Item>
      </Form>
    </Modal>;
};