import { DefaultOptionType, SelectProps } from "@/src/pattern-library";
import isNil from "lodash/isNil";
import isPlainObject from "lodash/isPlainObject";
import isNaN from "lodash/isNaN";

export const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const isValidJSON = (text: string): boolean => {
  try {
    JSON.parse(text);
  } catch (e) {
    return false;
  }
  return true;
};

export const convertArrayToSelectOptions = (
  items: string[]
): SelectProps["options"] =>
  items
    .filter((value) => value) // filter out empty value
    .map((value) => ({
      value,
      label: value,
    }));

export const convertEnumToSelectOptions = (
  source: Record<string | number, string>
): SelectProps["options"] =>
  Object.entries(source).map(([label, value]) => ({
    label,
    value,
  }));

export const isEmpty = (value?: unknown) =>
  value === null ||
  value === undefined ||
  (Array.isArray(value) && value.length === 0) || // handle empty array
  (typeof value === "string" && value.trim().length === 0) ||
  (isPlainObject(value) && Object.keys(value).length === 0);

export const cascaderSearchFilter = (
  inputValue: string,
  path: DefaultOptionType[]
) =>
  path.some((option) => {
    const label = option?.label ?? "";
    if (typeof label === "string") {
      return label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
    }
    return true; // logic is different from cascader
  });

export const selectSearchFilter = (
  input: string,
  option?: DefaultOptionType
) => {
  const label = option?.label ?? "";
  if (typeof label === "string") {
    return label.toLowerCase().includes(input.toLowerCase());
  }
  return false;
};

export const toNumber = (value?: unknown) => {
  const numberValue = !isNil(value) ? Number(value) : undefined; // isNil check because toNumber(null) === 0
  const finalValue =
    numberValue !== undefined && !isNaN(numberValue) ? numberValue : undefined;

  return finalValue;
};

export const sortAttributesOrder = <
  T extends { name: string; option?: { order?: number } },
>(
  attributes: T[]
): T[] => {
  const [attributesWithOrder, attributesWithoutOrder] = attributes.reduce(
    (accum, curr) => {
      const [withOrder, withoutOrder] = accum;
      if (curr?.option?.order !== undefined) {
        return [[...withOrder, curr], withoutOrder];
      }
      return [withOrder, [...withoutOrder, curr]];
    },
    [[], []] as [T[], T[]]
  );

  const sortedAttributesByOrderAndName = [
    ...attributesWithOrder,
    ...attributesWithoutOrder,
  ].sort((a, b) => {
    const leftNodeOrder = a?.option?.order;
    const rightNodeOrder = b?.option?.order;

    if (leftNodeOrder !== undefined && rightNodeOrder !== undefined) {
      return leftNodeOrder < rightNodeOrder ? -1 : 1;
    }
    if (leftNodeOrder !== undefined || rightNodeOrder !== undefined) {
      return 0;
    }
    return a.name < b.name ? -1 : 1;
  });

  return sortedAttributesByOrderAndName;
};

export const sortObjectOrderByArrayPosition = <T>({
  options,
  sortOrder,
  sortKey,
}: {
  options: T[];
  sortOrder: string[];
  sortKey: string;
}): T[] => {
  const lastPosition = sortOrder.length;

  return [...options].sort((a, b) => {
    const posA = sortOrder.indexOf(a[sortKey as keyof T] as string);
    const posB = sortOrder.indexOf(b[sortKey as keyof T] as string);

    return (posA < 0 ? lastPosition : posA) - (posB < 0 ? lastPosition : posB);
  });
};
