"use client";

import { HTMLAttributes } from "react";
import { Button } from "./Button";
import { CopyOutlined, LinkOutlined } from "@ant-design/icons";
import { useCopy } from "../hooks/useCopy";
import { Tooltip } from "./Tooltip";
import { useTranslations } from "next-intl";
import { Namespace } from "../i18n/namespace";
import clsx from "clsx";
import { Text } from "./Typography";
import { theme } from "./theme";
interface CopyableProps extends HTMLAttributes<HTMLDivElement> {
  text: string;
  isLink?: boolean;
}
export function Copyable({
  text,
  className,
  children,
  isLink,
  ...props
}: CopyableProps) {
  const {
    token: {
      marginXXS
    }
  } = theme.useToken();
  const tCommon = useTranslations(Namespace.COMMON);
  const {
    copyText
  } = useCopy();
  const handleOnClick = () => {
    copyText(text);
  };
  return <div {...props} className={clsx(className, "group flex w-full h-6")} data-sentry-component="Copyable" data-sentry-source-file="Copyable.tsx">
      <Text ellipsis={{
      tooltip: text
    }} data-sentry-element="Text" data-sentry-source-file="Copyable.tsx">{children}</Text>
      <Tooltip title={tCommon(isLink ? "shared.copyUrl" : "shared.copy")} data-sentry-element="Tooltip" data-sentry-source-file="Copyable.tsx">
        <Button size="small" className="!hidden group-hover:!block shrink-0" style={{
        marginLeft: marginXXS
      }} onClick={handleOnClick} icon={isLink ? <LinkOutlined /> : <CopyOutlined />} data-sentry-element="Button" data-sentry-source-file="Copyable.tsx" />
      </Tooltip>
    </div>;
}