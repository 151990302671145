"use client";

import { DownOutlined } from "@ant-design/icons";
import { Button, Flex, GetProps } from "antd";
import React, { useState } from "react";
export interface ExpandableProps {
  children: (props: {
    active: boolean;
  }) => React.ReactNode;
  title: Omit<GetProps<typeof Button>, "onClick" | "icon">;
}

// Expandable section without borders and padding, with a Title only
export function Expandable({
  children,
  title
}: ExpandableProps) {
  const [active, setActive] = useState(false);
  const toggleExpand = () => {
    setActive(curr => !curr);
  };
  return <Flex vertical gap="small" align="stretch" data-sentry-element="Flex" data-sentry-component="Expandable" data-sentry-source-file="Expandable.tsx">
      <div>
        <Button type="link" style={{
        paddingInline: 0
      }} {...title} onClick={toggleExpand} icon={<DownOutlined rotate={active ? 0 : -90} />} iconPosition="end" data-sentry-element="Button" data-sentry-source-file="Expandable.tsx" />
      </div>
      <div>{children({
        active
      })}</div>
    </Flex>;
}