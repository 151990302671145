export const isWithinMinMaxCheck = (
  value: number,
  min: number | undefined,
  max: number | undefined
) => {
  const adjustedMin = min ?? 0;
  const adjustedMax = max ?? Infinity;

  return value >= adjustedMin && value <= adjustedMax;
};

export const isValidPrecision = (precision?: number) =>
  precision !== undefined && !(!Number.isInteger(precision) || precision < 0);
